import * as React from 'react'
import {
  Container,
  Box,
  Button,
  Typography,
} from '@mui/material'

import AppBar from '../../components/AppBar'
import { DynamicLinks } from '../../constants/dynamic_links'

const CreditPaymentNgPage: React.FC = () => {

  // アプリに戻る処理
  const handleReturn = () => {
    const returnUrl = DynamicLinks.CREDIT_PAYMENT_CANCEL
    window.location.href = returnUrl
  }

  return (
    <div>
      <AppBar payType='クレジットカード決済' />

      <Box
        py={3}
        textAlign="center"
      >
        <Container>
          <Typography variant="h6" gutterBottom>
            クレジットカード決済の登録に失敗しました
          </Typography>

          <Button
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            style={{
              borderRadius: '24px',
              marginTop: '16px'
            }}
            onClick={handleReturn}
          >
            アイカサアプリに戻る
          </Button>
        </Container>
      </Box>
    </div>
  )
}

export default CreditPaymentNgPage
