import * as React from 'react'
import {
  Navigate,
  Route,
  Routes,
  BrowserRouter,
} from 'react-router-dom'

import Dpayment from './pages/dpayment'
import DpaymentOk from './pages/dpayment/ok'
import DpaymentNg from './pages/dpayment/ng'

import AuPayment from './pages/aupayment'
import AuPaymentOk from './pages/aupayment/ok'
import AuPaymentNg from './pages/aupayment/ng'
import Softbankpayment from './pages/softbankpayment'
import SoftbankPaymentOk from './pages/softbankpayment/ok'
import SoftbankPaymentNg from './pages/softbankpayment/ng'
import CreditPayment from './pages/creditpayment'
import CreditPaymentOk from './pages/creditpayment/ok'
import CreditPaymentNg from './pages/creditpayment/ng'

export default () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/index.html" element={<Navigate to="/" replace />} />
        <Route path="/dpayment" element={<Dpayment />} />
        <Route path="/dpayment/ok" element={<DpaymentOk />} />
        <Route path="/dpayment/ng" element={<DpaymentNg />} />

        <Route path="/aupayment" element={<AuPayment />} />
        <Route path="/aupayment/ok" element={<AuPaymentOk />} />
        <Route path="/aupayment/ng" element={<AuPaymentNg />} />

        <Route path="/softbank_payment" element={<Softbankpayment />} />
        <Route path="/softbank_payment/ok" element={<SoftbankPaymentOk />} />
        <Route path="/softbank_payment/ng" element={<SoftbankPaymentNg />} />

        <Route path="/credit_payment" element={<CreditPayment />} />
        <Route path="/credit_payment/ok" element={<CreditPaymentOk />} />
        <Route path="/credit_payment/ng" element={<CreditPaymentNg />} />
      </Routes>
    </BrowserRouter>
  )
}
