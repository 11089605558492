import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#00ABD8',
      dark: '#32497B',
      contrastText: '#ffffff'
    },
    text: {
      primary: '#000000',
      disabled: '#888888'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
  typography: {
    fontSize: 13,
    htmlFontSize: 13
  }
})

export default theme
