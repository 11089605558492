import * as React from 'react'
import { AppBar, Toolbar } from '@mui/material'

type IProps = {
  payType: string;
}

export default ({ payType }: IProps) => {
  return (
    <AppBar position="static">
      <Toolbar>
        <h1 style={{
          fontSize: '20px'
        }}>
          {payType} 決済情報登録
        </h1>
      </Toolbar>
    </AppBar>
  )
}
