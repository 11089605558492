import * as React from 'react'
import {
  Container,
  Box,
  Button,
} from '@mui/material'

import AppBar from '../../components/AppBar'
import { getParam } from '../../methods'
import { DynamicLinks } from '../../constants/dynamic_links'

export default () => {
  const [appType, setAppType] = React.useState('')

  React.useEffect(() => {
    setAppType(getParam('p'))
  }, [])

  return (
    <div>
      <AppBar payType='ソフトバンク決済' />

      <Box
        py={3}
        textAlign="center"
      >
        <Container>
          <p>ソフトバンク決済の登録に成功しました！</p>

          <Button
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            style={{
              borderRadius: '24px'
            }}
            onClick={() => {
              const redirectUrl = (appType === 'app')
                ? DynamicLinks.SOFTBANK_PAYMENT_COMPLETE
                : 'https://liff.i-kasa.com/mypage'
              window.location.href = redirectUrl
            }}
          >
            {
              (appType === 'app')
                ? 'アイカサアプリに戻る'
                : 'アイカサLINEアプリに戻る'
            }
          </Button>
        </Container>
      </Box>
    </div>
  )
}
