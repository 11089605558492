import * as React from 'react'
import {
  Box,
  Button,
  Container,
} from '@mui/material'

import AppBar from '../../components/AppBar'
import { getParam } from '../../methods'

export default () => {
  const [redirectUrl, setRedirectUrl] = React.useState('')
  const [showRedirectPage, setShowRedirectPage] = React.useState(false)

  const handleRedirect = () => {
    // リダイレクトページを表示
    setShowRedirectPage(true)
  }

  React.useEffect(() => {
    const redirectUrl = decodeURI(getParam('redirectUrl'))
    setRedirectUrl(redirectUrl)
  }, [])

  // リダイレクトページを表示する場合
  if (showRedirectPage) {
    return (
      <html>
        <head>
          <meta httpEquiv="refresh" content={`0; URL=${redirectUrl}`} />
          <title>リダイレクト中...</title>
        </head>
        <body>
          <p>リダイレクト中...</p>
        </body>
      </html>
    )
  }

  return (
    <div>
      <AppBar payType='クレジットカード決済登録' />

      <Box
        py={3}
        textAlign="center"
      >
        <Container>
          <p>クレジットカード決済の3Dセキュア認証画面へ遷移します。</p>

          <Button
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            style={{
              borderRadius: '24px'
            }}
            onClick={handleRedirect}
          >
            認証画面に進む
          </Button>
        </Container>
      </Box>
    </div>
  )
}
