import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import Router from './Router'
import GlobalStyle from './styles/globalStyle'

import theme from './styles/theme'

export default () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Router />
  </ThemeProvider>
)
